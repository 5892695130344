/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAroP7onz4WpbSrVHvf6suR_2TiZc3MOjk",
  "appId": "1:949307153444:web:43d123c5163583ef8e4cb1",
  "authDomain": "schooldog-i-hall-ga.firebaseapp.com",
  "measurementId": "G-548XKMT3HP",
  "messagingSenderId": "949307153444",
  "project": "schooldog-i-hall-ga",
  "projectId": "schooldog-i-hall-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-hall-ga.appspot.com"
}
